import "$styles/index.css"

class FloatingTypewriter extends HTMLElement {
  constructor() {
    super();
  }

  connectedCallback() {
    this.#randomizeHeight()
    this.#randomizePlacement()
  }

  #randomizeHeight() {
    const topOffset = Math.floor(Math.random() * 70) + 20
    this.style.setProperty("--top-offset", `${topOffset}%`)
  }

  #randomizePlacement() {
    const placement = Math.round(Math.random()) == 1 ? "left" : "right"

    if (placement == "left") {
      this.style.setProperty("--left-offset", "-2vw")
      this.style.setProperty("--right-offset", "auto")
      this.style.setProperty("--rotation", "-12deg")
    } else {
      this.style.setProperty("--right-offset", "-3vw")
      this.style.setProperty("--left-offset", "auto")
      this.style.setProperty("--rotation", "7deg")
    }
  }
}

customElements.define("floating-typewriter", FloatingTypewriter);
